import React from "react";
import { Link } from "react-router-dom";

const Youtube = ({newsletterdata}) => {
  const sortedData = newsletterdata?.sort((a, b) => new Date(b.id) - new Date(a.id));
  const splitData = sortedData?.slice(0, 3);
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <div className="section-heading">
            <h2 className="entry-title">Latest Videos</h2>
          </div>
          <div
            className="embed-responsive embed-responsive-16by9 mb-4"
            style={{ height: "370px" }}
          >
            <iframe
              width={560}
              height={315}
              src="https://www.youtube.com/embed/5v5kHYxn8AY?si=ZI7uqr4jhJVs5dXd"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen=""
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="section-heading">
            <h2 className="entry-title">Monthly Newsletter</h2>
          </div>
          <div className="scrollable-content" id="newsletterprog">
            {splitData?.length > 0 ? (
              splitData?.map((newsletter) => (
                <div
                  key={newsletter.id}
                  className="card shadow mb-2"
                  style={{ maxWidth: "585px", height: "100px" }}
                >
                  <div className="card-body text-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png"
                        alt="PDF Icon"
                        className="mb-3"
                        style={{ width: "50px" }}
                      />
                      <div className="text-center">
                        <h5 className="card-title mb-2">{newsletter?.title}</h5>
                        <p className="card-text mb-3">{newsletter?.eventdate}</p>
                      </div>
                      <a
                        href={newsletter?.file_url}
                        className="btn btn-sm btn-primary"
                        download
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>
                <b>No Newsletter available</b>
              </p>
            )}

            {splitData?.length >= 3 && (
              <center>
                <Link
                  to={"/newsletter"}
                  style={{
                    backgroundColor: "#012c6d",
                    color: "white",
                    textDecoration: "none",
                    display: "inline-block",
                    padding: "8px 8px",
                    borderRadius: "5px",
                    marginTop: "10px",
                    fontSize: "14px",
                    className: "btn-sm",
                  }}
                >
                  View More
                </Link>
              </center>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Youtube;
